import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { io } from "socket.io-client";
import { debounce, getApi, postApi } from "./utils/HelperFunction";
import Linkify from "react-linkify";
import { API_BASE_URL, REACT_APP_IMAGE_BUCKET_ENDPOINT } from "./utils/BaseUrl";
import axios from "axios";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { botAllVoices } from "./utils/HelperFunction";
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { ThreeDots } from 'react-loader-spinner'
import RecordRTC from 'recordrtc';

  //  for adding link in text
  const renderDynamicContentWithLink = (content)=>{
    const regex = /\[([^\]]*)\]\(([^)]*)\)/g;
    let lastIndex = 0;
    const elements = [];
    
    let match;
    while ((match = regex.exec(content)) !== null) {
      const text = match[1];
      const url = match[2];
      
      // Add the text before the link
      elements.push(content.substring(lastIndex, match.index));
      
      // Add the link
      elements.push(
        <a href={url} target="_blank" key={url}>
          {text}
        </a>
      );
      
      lastIndex = regex.lastIndex;
    }
    
    // Add the remaining text
    elements.push(content.substring(lastIndex));
    
    return elements;
  };
  
  // for checking need to add link in text
  const isOnlyContentLink = (content)=>{
    const regex = /\[([^\]]*)\]\(([^)]*)\)/g;
    return regex.test(content);
  }


const MessageWithLinks = ({ message }) => {
  return (
    <>
      {isOnlyContentLink(message) ? (
        renderDynamicContentWithLink(message)
      ) : (
        <Linkify>{message}</Linkify>
      )}
    </>
  );
};

  

const ChatExtensionView = (props) => {
  const [socket, setSocket] = useState("");
  const [msg, setMsg] = useState("");
  const recorderControls = useAudioRecorder()
  const [msgList, setMsgList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [typing, setTyping] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [clinicOnline, setClinicOnline] = useState(false);
  const [iloadering,setIloadering] = useState(false)
  const [disabled, setDisabled] = useState({message:false,field:false});
  const [recentConversation,setRecentConversation] = useState({limit:0,message:""})
  const [isvoiceConversation,setIsVoiceConversation] = useState({status:false,message:""})
  const [botMessage,setBotMessage] = useState({message:"",isAudio:false,audioBuffer:null})
  const [botvoice,setBotVoice] = useState({voice:null,pitch:1.1,rate:0.95})
  const [audioUrl,setAudioUrl] = useState(null)
  const [transcription, setTranscription] = useState({loading:false,success:false,message:""});
  const [isCoutinue,setIsCoutinue] = useState({bot:false,user:false})
  const botSpeechSynthesis = window.speechSynthesis;
  const {
    transcript,
    listening,
    resetTranscript,
    isMicrophoneAvailable,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();
  const startTimeRef = useRef(null);

  const business = props?.data?.businessData;
  const msgRef = useRef(null);
  const waveRef = useRef(null);
  const textareaRef = useRef(null)
  var adminLive = false;

  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState("");
  const mediaRecorderRef = useRef(null);
  const audioContextRef = useRef(null);
  const streamRef = useRef(null); // Store the MediaStream
  const chunks = useRef([]);
  const audioRef = useRef(null);
  let recorder = useRef();
  const [startTime, setStartTime] = useState(null); // Save start time

  const addAudioElement = (audioBlob) => {
    // console.log("audioBlob: ", audioBlob);
    if(socket){
      // console.log("calling audioBlob");
      setAudioUrl(null);
      setTranscription({...transcription,message:"",loading:true})
      socket?.emit('audioBlob', audioBlob);
    }
  };

  // console.log("my msglist:",msgList);
  useEffect(()=>{
    if(!listening && transcript){
      postMsg("",msg)
      resetTranscript()
      setMsg("")
      botSpeechSynthesis.cancel()
    }else{
      botSpeechSynthesis.cancel()
      setMsg(transcript)
    }
    },[transcript,listening])

    const playAudio = (audioBuffer) => {
      if (audioRef?.current) {
        const blob = new Blob([audioBuffer], { type: "audio/mp3" }); // Adjust MIME type as needed
        const newAudioUrl = URL.createObjectURL(blob);
        audioRef.current.src = newAudioUrl;
        audioRef.current.loop = false; // Do not loop the audio
        audioRef.current
          .play()
          .then(() => {
            // console.log("Audio played successfully.");
          })
          .catch((error) => {
            // console.error("Error playing audio:", error);
          });
      }
    };

    useLayoutEffect(() => {
      if(transcription?.message && botMessage.audioBuffer && botMessage.isAudio){
        playAudio(botMessage.audioBuffer)
        setTranscription({...transcription,message:""})
        setBotMessage({
          message: "",
          isAudio: false,
          audioBuffer: null,
      })
        
      }else{
       setBotMessage({message:"",isAudio:false,audioBuffer:null})
      }
      return () => {
        if (audioUrl) {
          URL.revokeObjectURL(audioUrl);
          setAudioUrl(null);
        }
      };
  }, [transcription?.message,botMessage.message]);

  const handleVoiceConversation =()=>{
    if(recorderControls.isRecording){
      recorderControls.stopRecording()
    }else{
      recorderControls.startRecording()
    }
  }

  if (!browserSupportsSpeechRecognition &&!isMicrophoneAvailable) {
  }else{
  }



  const getAllChats = async () => {
    setLoader(true);
    await getApi(
      `/user/getChats?page=1&roomID=${props?.roomId}&businessId=${business?.id}`
    ).then((result) => {
      if (result.status === 201) {
        // console.log("404");
      } else {
        setMsgList(result?.data?.data);
        setTimeout(() => {
          msgRef?.current?.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
          handleRead();
        }, 50);
      }
    });
    setLoader(false);
  };

  const sendChatToOwner = async (lastReply,lastMessage) => {
    try {
    const sendingData = {
      id:business?.id,
      chat:[{
        message: `Welcome to ${props?.clinicName}!`,
        user: "bot",
        room: props?.roomId,
        read: false,
        containsFile: false,
        replyMessage: false,
        isClinic: true,
        timeSent: new Date(),
        botId: business?.botId,},
      {
        message: `How may I help you?`,
        user: "bot",
        room: props?.roomId,
        read: false,
        containsFile: false,
        replyMessage: false,
        isClinic: true,
        timeSent: new Date(),
        botId: business?.botId,
      },
      ...msgList,
      {
        message: lastMessage,
        user: "bot",
        room: props?.roomId,
        read: false,
        containsFile: false,
        replyMessage: false,
        isClinic: true,
        timeSent: new Date(),
        botId: business?.botId,
      }],
      patientPhone:props?.userDetails?.patientPhone,
      patientEmail:props?.userDetails?.patientEmail,
      patientZipCode:props?.userDetails?.patientZip
    }
    const res =   await axios.post(`${API_BASE_URL}/admin/sab_send_chatHistory`,sendingData)
    // console.log("chat history sending",res);
     if(res.status === 200){
      // console.log("Successfully Send Chat");
     } 
    } catch (error) {
      // console.log("chat history sending err",error);
    }

  }

  const postMsg = (message = "",msg) => {
    // console.log("postMsg",msg,message);
    setIsAlert(false);
    const obj = {
      message: message.length ? message : (transcription?.message ? transcription?.message : msg),
      user: message?.length ? "bot" : "patient",
      room: props?.roomId,
      read: false,
      containsFile: false,
      replyMessage: false,
      isClinic: message.length ? true : false,
      timeSent: new Date(),
      botId: business?.botId,
      clinicId:props?.clinicId,
      isAudio:transcription?.message ? true :false,
      voice:botAllVoices.includes(business?.botVoice?.toLowerCase()) ? business?.botVoice?.toLowerCase() : "alloy",
      isCoutinue:isCoutinue?.user,
      isShortCode:true,
    };

    // console.log("postMsg",msg,message,obj,props);

    if (Boolean(obj.message.replace(/\s/g, "")) || isCoutinue?.user) {
      if (socket) {
        if(!isCoutinue?.user){
          setMsgList((msgList) => {
           return (msgList || []).concat(obj);
           });
        }
        socket?.emit("server_message", obj);
        setMsg("");
        setTimeout(() => {
          msgRef?.current?.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }, 50);
        // setRecentConversation((recentConversation)=>recentConversation+1)
        setDisabled({...disabled,message:true})
        setIloadering(true)
        setIsCoutinue({bot:false,user:false})
      }
    }
  };
  useEffect(()=>{
    document.getElementById('shortCode-input')?.focus()
    // textareaRef?.current?.focus()
  },[disabled?.message])

  useEffect(()=>{
  if(transcription.message){
    postMsg("",msg)
  }else if(transcription?.success && !transcription?.message && transcription?.emptyText){
    setTranscription({...transcription,emptyText:false})
    const obj = {
      message: "I'm sorry, I couldn't hear that clearly. Could you please say it again?",
      user: "patient",
      room: props?.roomId,
      read: false,
      containsFile: false,
      replyMessage: false,
      isClinic: false,
      timeSent: new Date(),
      botId: business?.botId,
      clinicId: props?.clinicId,
      isAudio:false,
      voice:business?.botVoice?.toLowerCase(),
      isCoutinue:false
    };
    setMsgList((msgList) => {
      return (msgList || []).concat(obj);
      });
  }
  },[transcription])


//    ---- sending user chat on admin email ---------
//     useEffect(()=>{
//     if (business?.botConversationLimit != 0 && business?.botConversationLimit <= recentConversation?.limit) {
//       setDisabled({ message: true, field: true })
//       setMsgList((msgList) => {
//         return (msgList || []).concat({
//           message: business?.botLastMessage || "Our team contact you shortly",
//           user: "bot",
//           room: props?.roomId,
//           read: false,
//           containsFile: false,
//           replyMessage: false,
//           isClinic: true,
//           timeSent: new Date(),
//           botId: business?.botId,
//         })
//       })
//       sendChatToOwner(recentConversation?.message,business?.botLastMessage || "Our team contact you shortly")
//     }
//   },[recentConversation])

  const handleTextAreaHeight = (e)=>{
    // console.log("onKeyUp",e.keyCode,e.key)
    textareaRef.current.style.height = "1px";
    textareaRef.current.style.height = (10+textareaRef.current.scrollHeight)+"px"; 
  }

  const handleChange = (msg1,e) => {
    console.log("handleChange",e?.target?.value);
    
    setMsg(msg1);
    setIsVoiceConversation({...isvoiceConversation,status:false})
    resetTranscript()
    const obj = { roomId: props?.roomId, user: "patient" };
    socket.emit("typing", obj);
  };

 useEffect(()=>{
  if(props?.hasSession && props?.roomId){
    getAllChats();
  }
 },[props?.hasSession,props?.roomId])


  useEffect(() => {
    if(props?.roomId && props?.showChat){
      const socket = io(API_BASE_URL, {
        query: { patient: true, clinic: false, roomId: props?.roomId,sendChatEmail:!business?.sendChatEmail,businessId:business?.id },
      });
      setSocket(socket);
      socket.on("connect", () => {
        socket.emit("joinRoom", { roomId: props?.roomId, user: "patient" });
      });
  
      socket.on("patient_message2", (newMessage) => {
        if (newMessage.user == "bot") {
          setIloadering(false)
          setDisabled({...disabled,message:false})
          setMsgList((msgList) => { return (msgList || []).concat(newMessage)});
          setIsCoutinue((pre)=>{return {...pre,bot:newMessage?.isCoutinue}})
          setBotMessage({message:newMessage.message,isAudio:newMessage?.isAudio,audioBuffer:newMessage?.audioBuffer})
          setRecentConversation((recentConversation)=>{return {limit:recentConversation.limit+1,message:newMessage}})
        }else{
          setMsgList((msgList) => { return (msgList || [])})
        }
        if (newMessage?.message == "I am sorry. I do not have the answer for that. Let me connect you with our support team!") {
          setIsAlert(true)
        }
        setTyping(false);
        setTimeout(() => {
          msgRef?.current?.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }, 50);
      });
      socket.on('transcription', (data) => {
        if(data?.success){
          setTranscription(()=>{return {loading:false,success:true,message:data?.transcription,emptyText:!(Boolean(data?.transcription))}});
        }else{
          setTranscription(()=>{return {loading:false,success:false,message:""}});
        }
      });
  
      socket.on("WaitForAdmin", (waitMsg) => {
        setMsgList((msgList) => {
          return (msgList || [])
            .filter((item) => item.timeSent !== waitMsg.timeSent)
            .concat(waitMsg);
        });
        setTimeout(() => {
          msgRef?.current?.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }, 50);
      });
      const loader = debounce(() => {
        if (!adminLive) {
          adminLive = true;
          postMsg("Chat is now transferred to the admin",msg);
        }
        setTyping(false);
      }, 3000);
      socket.on("isTyping", (user) => {
        if (user?.user == "clinic") {
          setIsAlert(false);
          setTyping(true);
          setTimeout(() => {
            waveRef?.current?.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          }, 50);
          loader();
        }
      });
      socket.on("readRecipt", (data) => {
      });
      socket.on("roomJoined", (newMessage) => {
        if (newMessage.user == "patient") {
          socket.emit("newJoin", { roomId: props?.roomId, user: "patient" });
        }
      });
  
      socket.connect();
  
      return () => {
        let obj ={}
        if(business?.sendChatEmail){
          obj ={ roomId: props?.roomId, user: "patient",sendChatEmail:!business?.sendChatEmail}
        }else{
          obj ={ roomId: props?.roomId, user: "patient",sendChatEmail:!business?.sendChatEmail,businessId:business?.id};
        }
        // obj = { roomId: props?.roomId, user: "patient",msgList,businessId:business?.id,patient:props?.userDetails};
        socket.emit("leaveRoom", obj);
        socket.disconnect();
      };
    }

  }, [props?.showChat,props?.roomId]);

  const handleRead = () => {
    if (socket){
      socket.emit("msgRead", { roomId: props?.roomId, user: "patient" });
    }
  };


  const transferChat = async (status) => {
    const roomId = props?.roomId;
    if (status === true) {
      await postApi("/clinic/saa_disable_bot", roomId).then((res) => {
        if (res?.status === 200) {
          adminLive = true;
          setIsAlert(false);
          postMsg("Chat is now transferred to the admin",msg);
        }
      });
    } else {
      postMsg("Chat is not transferred to the admin",msg);
      setIsAlert(false);
    }
  };

  const handleAudioEnded = () => {
    // Set the audio URL to null when the audio ends
    setAudioUrl(null);
  };

  const handleContinue = ()=>{
    setIsCoutinue({...isCoutinue,user:true});
   }
useEffect(()=>{
  if(isCoutinue?.user){
    postMsg("",msg)
  }
},[isCoutinue.user])


useEffect(()=>{
  // if(textareaRef?.current){
  //   textareaRef.current?.focus()
  // }
  document.getElementById('shortCode-input')?.focus()
},[])

//  testing through mediaRecorderRef


const handleKeyDown = (e) => {
  console.log(e.target.value,"sjkdfh");
  if (e.key === "Enter" && !e.shiftKey) {
    // console.log(e.target.value, "key enger",msg)
    e.preventDefault();
    
    postMsg("",e.target.value); // Call your function
  }
};


useEffect(() => {
  // Attach the event listener
  let textarea = document.getElementById('shortCode-input')
  textarea?.addEventListener("keydown", handleKeyDown);
  
  return () => textarea?.removeEventListener?.("keydown", handleKeyDown);
}, [props,loader]);

// Initialize the audio object during user interaction
const initializeAudio = (type) => {
if (audioRef?.current && type=="start") {
    audioRef.current.src = "";
    audioRef.current
      ?.play()
      ?.then(() => {
        // console.log("Muted audio played for permissions")
      })
      ?.catch((err) => {
        // console.error("Error playing static audio:", err)
      });
  }
};

const startRecording = async () => {
  try {
    const stream = await window.navigator.mediaDevices.getUserMedia({ audio: true });
    setIsRecording(true);  
    
    let recorder1 = new RecordRTC(stream, {
      type: 'audio',
      mimeType: 'audio/webm', // Use 'audio/wav' if required on the server side
      recorderType: RecordRTC.StereoAudioRecorder,

    });
    recorder.current = recorder1;
    // Initialize the Audio object
    
    setStartTime(new Date()); // Save the recording start time
    recorder.current.startRecording();
  } catch (error) {
    console.error('Error accessing microphone:', error);
  }
};

const stopRecording = async () => {
  const recordingState = recorder.current?.getState();
  // console.log("stopRecording",new Date().getTime(),recordingState);
  setIsRecording(false);
  
  if (recorder.current && recordingState == "recording") {
    recorder.current.stopRecording(() => {
      const audioBlob = recorder.current.getBlob();

       // Calculate duration
       const endTime = new Date();
       const duration = (endTime - startTime) / 1000; // Duration in seconds

       if (duration >= 0.5) {
        // Emit the audio blob if duration is 1 second or more
        setTranscription({ ...transcription, message: "", loading: true });
        socket?.emit('audioBlob', audioBlob);
        // console.log(`Audio blob emitted. Duration: ${duration}s`);
      } else {
        // console.log(`Audio blob ignored. Duration: ${duration}s`);
      }
    });
  }

};

let holdTimeout;

const handleMouseEvents = (e, action) => {
  if(window.innerWidth > 768){
    if (action === "start") {
      e.preventDefault();
      e.stopPropagation();
      holdTimeout = setTimeout(() => {   
        startRecording();
      }, 200); // Wait for 500ms before starting recording
    } else if (action === "stop") {
      clearTimeout(holdTimeout); // Clear timeout if released before 500ms
      if (isRecording) {
        stopRecording();
      }
    }
  }  
};

const handleTouchEvents = (e, action) => { 
  if(window.innerWidth <= 768){
  if (action === "start") {
    holdTimeout = setTimeout(() => {  
      // setIsRecording(true)
      if(!isRecording){
        startRecording();
      } 
    }, 200); // Wait for 500ms before starting recording
  } else if (action === "stop") {
    clearTimeout(holdTimeout); // Clear timeout if released before 500ms
    if (isRecording) {
      // setIsRecording(false) 
      stopRecording();
    }
  }
}
};



  return (
    <div className={`chat-wrapper chat-wrapper-shortcode shortcode_backgroundColor`} style={{ display: `${props?.showModal ? "block" : "none"} `}}>
      <div className="chat-wrapper-inner">
        <div className="shortcode_chat-box">
          {props.showChat && !props?.botLoading ? (
            <div className="row">
              <div className="shortCode-scroll-div" onClick={()=>(!disabled?.message || !transcription.loading) &&  document.getElementById('shortCode-input')?.focus()}>
                {loader ? (
                  <div className="spinner-border" role="status"></div>
                ) : (
                  <div className="new-chat-cntnr" >
                    <div className="d-flex">
                      <div className="col-12">
                        <div className="shortCode-comment-box">
                            <p className="shortCode-comment-box-msg1 nunito-shortCode-text">Hi!</p>
                           
                        </div>
                      </div>
                      {audioUrl && <audio autoPlay src={audioUrl} onEnded={handleAudioEnded}/>}
                    </div>
                      <div className="mic-postion">
                        {business?.isVoiceChat && (
                          <button
                            className={`btn animated zoomIn faster rounded-5 mic-bg mic-button recording-button ${isRecording ? "recording" : ""}`}
                            disabled={disabled?.message || transcription.loading}
                            onMouseDown={(e) =>{ handleMouseEvents(e, "start");initializeAudio("start")}}
                            onMouseUp={(e) => {handleMouseEvents(e, "stop");initializeAudio("stop")}}
                            onTouchStart={(e) => {handleTouchEvents(e, "start");initializeAudio("stop")}}
                            onTouchEnd={(e) => {handleTouchEvents(e, "stop");;initializeAudio("stop")}}
                          >
                            {isRecording ? (
                              <i className={`${transcription?.loading ? "spinner-border spinner-border-sm" : "bi bi-mic"}`}></i>
                            ) : (
                              <i className={`${transcription?.loading ? "spinner-border spinner-border-sm" : "bi bi-mic-mute"}`}></i>
                            )}
                            <span className="custom-tooltip">
                              {isRecording ? "Stop speaking" : "Start speaking"}
                            </span>
                          </button>
                        )}

                      </div>
                    {msgList?.length
                      ? msgList.map((obj, i, s) => {
                        return !obj?.isClinic ? (
                          <div key={i} className="d-flex">
                            <div className="shortCode-comment-box">
                              <p className="nunito-shortCode-text">
                                <Linkify>{obj?.message}</Linkify>
                              </p>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div key={i} className="d-flex">
                              <div className="col-md-10">
                                <div className="shortCode-comment-box-new">
                                  <p className="nunito-shortCode-text">
                                  {/* {isOnlyContentLink(obj?.message) ? renderDynamicContentWithLink(obj?.message) : <Linkify>{obj?.message}</Linkify> } */}
                                  {/* {<Linkify>{obj?.message}</Linkify> } */}
                                  <MessageWithLinks message={obj?.message} />
                                    {/* <Linkify>{obj?.message} </Linkify> */}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })
                      : ""}
                     { isCoutinue?.bot && <div onClick={handleContinue} className="d-flex justify-content-end">
                     <div className="shortCode-continue">
                      Continue
                      </div>
                      </div>}
                
                      {iloadering ? (<>
                        {/* <div className=" " role="status"> <img src="https://api.yekaterin.com/chat/assets/landing/dot-loader.gif" alt="searching" width={70} height={40} /></div> */}
                        <ThreeDots
                          visible={true}
                          height="50"
                          width="50"
                          color="rgba(63,94,251,1)"
                          radius="5"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </>
                      ) : ""}
                      {!disabled?.field && <div className=" d-flex gap-3 align-items-center justify-content-center shortcode_backgroundColor">
                  <input
                  id="shortCode-input"
                  onChange={(e) => {
                    (!disabled?.message || !transcription.loading) &&  handleChange(e.target.value, e)                  
                  }}
                  onFocus={handleRead}
                  value={msg}
                  // rows={3}
                  ref={textareaRef}
                  disabled={disabled?.message || transcription.loading}
                  placeholder={props?.data?.businessData?.botName ? `Have questions? ${props?.data?.businessData?.botName} can help.` : "How can I assist you today?"}
                  className="w-100 shortCode-input ps-2 border-0 shortcode_backgroundColor"
                  // style={{maxHeight:'60px',height:'43px'}}
                 />
               </div>}
                    {typing && (
                      <div ref={waveRef} className="comment-box-new">
                        <div id="wave">
                          <span className="dot"></span>
                          <span className="dot"></span>
                          <span className="dot"></span>
                        </div>
                      </div>
                    )}
                    <div ref={msgRef} onLoad={() => alert("hi")}></div>
                  </div>
                )}
              </div>
              <div style={{display:'none'}}>
                <AudioRecorder 
                onRecordingComplete={(blob) => addAudioElement(blob)}
                recorderControls={recorderControls}
                />
                </div>
            </div>
          ) :<>
              {props?.botLoading && <div className="chat-bot"><div className="row">
                <div style={{height:500}} className="d-flex gap-2  align-items-center justify-content-center">
                <div class="spinner-grow spinner-grow-sm bg-purple" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow spinner-grow-sm bg-purple" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow spinner-grow-sm bg-purple" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
          </div>
                </div></div> }
          </>}
          <div className="free-chat">
                <span>
                Powered by{" "}
                  <a href="https://yekaterin.com/" target="_blank">
                 Yekaterin
                  </a>{" "}
                 
                </span>
              </div>
        </div>
      </div>
    </div>
  );
};

export default ChatExtensionView;
